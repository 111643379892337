import { axiosBaseQuery } from '@client';
import { RequestCaseWizardResponse } from '@module/request/model/RequestCaseWizardResponse';
import { RequestsActivitiesExpensesFormV2 } from '@module/request/model/RequestsActivityForm';
import { RequestsAgentsStepForm } from '@module/request/model/RequestsAgentsStepForm';
import { RequestsWizardActivitiesExpensesResponse } from '@module/request/model/RequestsWizardActivitiesResponse';
import { RequestsWizardAgentsResponse } from '@module/request/model/RequestsWizardAgentsResponse';
import { SupplierResponse } from '@module/supplier/model/SupplierResponse';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ActivityResponse,
  ExpenseCatalogueItemResponse,
  ExpenseRequestForm,
  MostUsedExpenseItem,
  RefData,
  RequestsWizardExpensesResponse,
  RequestsWizardStepResponse,
  SubmitedRequestsWizardExpensesResponse,
  SubmittedRequestsWizardVesselPortResponse,
  VesselPortRequestForm,
  VesselResponse
} from '../../types';

export const buyerDaToolRequestWizardApi = createApi({
  reducerPath: 'buyerDaToolRequestWizard',
  tagTypes: [
    'requestWizardStep1SubmittedVesselPort',
    'requestWizardStep3SubmitedExpenses',
    'requestCaseAgents',
    'requestWizardStep4LoadWizard',
    'requestWizardActivitiesExpenses'
  ],
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    fetchSubmittedVesselPort: build.query<
      SubmittedRequestsWizardVesselPortResponse,
      { requestCaseId: string }
    >({
      query: ({ requestCaseId }) => ({
        url: `/secured/request-case/${requestCaseId}/load-vessel-port-step`,
        method: 'GET'
      }),
      transformResponse: (
        response: SubmittedRequestsWizardVesselPortResponse
      ) => {
        return { ...response, portId: response.portId?.toString() };
      },
      providesTags: ['requestWizardStep1SubmittedVesselPort']
    }),
    saveRequestWizardStep1V1: build.mutation<
      RequestsWizardStepResponse,
      VesselPortRequestForm
    >({
      query: (data) => ({
        url: '/secured/request-case/save-step-1',
        method: 'POST',
        data
      }),
      invalidatesTags: ['requestWizardStep1SubmittedVesselPort']
    }),
    saveRequestWizardStep1: build.mutation<
      RequestsWizardStepResponse,
      VesselPortRequestForm
    >({
      query: (data) => ({
        url: '/secured/request-case/save-vessel-port',
        method: 'POST',
        data
      }),
      invalidatesTags: [
        'requestWizardStep1SubmittedVesselPort',
        'requestCaseAgents'
      ]
    }),
    fetchHusbandyExpenseCatalogues: build.query<
      ExpenseCatalogueItemResponse[],
      void
    >({
      query: () => ({
        url: '/secured/catalogue-expenses/HUSBANDRY_EXPENSES/list',
        method: 'GET'
      })
    }),
    fetchSubmitedExpenses: build.query<
      SubmitedRequestsWizardExpensesResponse,
      { requestCaseId: string }
    >({
      query: ({ requestCaseId }) => ({
        url: `/secured/request-case/${requestCaseId}/load-expenses-step`,
        method: 'GET'
      }),
      providesTags: ['requestWizardStep3SubmitedExpenses']
    }),
    saveRequestWizardStep2V1: build.mutation<
      {
        id: string;
        activities: ActivityResponse[];
        port: RefData;
        agents: SupplierResponse[];
        vessel: VesselResponse;
      },
      {
        id: number;
        agents: string[];
        activities: {
          key: string;
          id: string;
          typeId: string;
          commodityId?: string;
          cargoId?: string;
          quantity?: number | null;
          uom?: string;
          remarks?: string;
        }[];
      }
    >({
      query: (expenseData) => ({
        url: '/secured/request-case/save-step-2',
        method: 'POST',
        data: expenseData
      })
    }),
    saveRequestWizardStep2: build.mutation<
      {
        id: string;
        activities: ActivityResponse[];
      },
      {
        id: number;
        activities: {
          key: string;
          id: string;
          typeId: string;
          commodityId?: string;
          cargoId?: string;
          quantity?: number | null;
          uom?: string;
          remarks?: string;
        }[];
      }
    >({
      query: (expenseData) => ({
        url: '/secured/request-case/save-step-2',
        method: 'POST',
        data: expenseData
      })
    }),
    saveRequestWizardStep3V1: build.mutation<
      RequestsWizardExpensesResponse,
      ExpenseRequestForm
    >({
      query: (expenseData) => ({
        url: '/secured/request-case/save-step-3',
        method: 'POST',
        data: expenseData
      }),
      invalidatesTags: ['requestWizardStep3SubmitedExpenses']
    }),
    saveRequestWizardStep3: build.mutation<
      RequestsWizardAgentsResponse,
      RequestsAgentsStepForm
    >({
      query: (expenseData) => ({
        url: '/secured/request-case/save-agents',
        method: 'POST',
        data: expenseData
      }),
      invalidatesTags: ['requestCaseAgents', 'requestWizardStep4LoadWizard']
    }),
    fetchPastCaseExpenses: build.query<
      MostUsedExpenseItem[],
      { requestCaseId: string }
    >({
      query: ({ requestCaseId }) => ({
        url: `/secured/request-case/${requestCaseId}/load-historical-expenses`,
        method: 'GET'
      }),
      keepUnusedDataFor: 0
    }),
    fetchRequestCaseWizard: build.query<
      RequestCaseWizardResponse,
      { requestCaseId: string }
    >({
      query: ({ requestCaseId }) => ({
        url: `/secured/request-case/${requestCaseId}/load-wizard`,
        method: 'GET'
      }),
      providesTags: ['requestWizardStep4LoadWizard']
    }),
    submitRequestWizardStep4V1: build.mutation<
      RequestsWizardExpensesResponse,
      FormData
    >({
      query: (data) => {
        return {
          url: '/secured/request-case/submit-step-4',
          method: 'POST',
          data,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          formData: true
        };
      }
    }),
    submitRequestWizardStep4: build.mutation<
      RequestsWizardExpensesResponse,
      FormData
    >({
      query: (data) => {
        return {
          url: '/secured/request-case/submit-wizard',
          method: 'POST',
          data,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          formData: true
        };
      }
    }),
    loadActivitiesAndAgents: build.query<
      {
        id: string;
        activities: ActivityResponse[];
        port: RefData;
        agents: SupplierResponse[];
        vessel: VesselResponse;
      },
      { requestCaseId: string | number }
    >({
      query: ({ requestCaseId }) => ({
        method: 'GET',
        url: `/secured/request-case/${requestCaseId}/load-activities-agents-step`
      })
    }),
    loadAgents: build.query<
      RequestsWizardAgentsResponse,
      { requestCaseId: string | number }
    >({
      query: ({ requestCaseId }) => ({
        method: 'GET',
        url: `/secured/request-case/${requestCaseId}/load-agents`
      }),
      providesTags: ['requestCaseAgents']
    }),
    loadActivitiesExpensesStep: build.query<
      RequestsWizardActivitiesExpensesResponse,
      { requestCaseId: string | number }
    >({
      query: ({ requestCaseId }) => ({
        method: 'GET',
        url: `/secured/request-case/${requestCaseId}/load-activities-expenses-step`
      }),
      providesTags: (res) =>
        res
          ? [{ type: 'requestWizardActivitiesExpenses', id: res.id }]
          : ['requestWizardActivitiesExpenses']
    }),
    saveActivitiesExpensesStep: build.mutation<
      { id: string | number },
      RequestsActivitiesExpensesFormV2
    >({
      query: (data) => ({
        method: 'POST',
        url: `/secured/request-case/save-activities-expenses-step`,
        data
      }),
      invalidatesTags: (res) =>
        res
          ? [{ type: 'requestWizardActivitiesExpenses', id: res.id }]
          : ['requestWizardActivitiesExpenses']
    })
  })
});

export const invalidateRequestCaseAgents = () =>
  buyerDaToolRequestWizardApi.util.invalidateTags(['requestCaseAgents']);

export const {
  useFetchSubmittedVesselPortQuery,
  useSaveRequestWizardStep1V1Mutation,
  useSaveRequestWizardStep1Mutation,
  useFetchHusbandyExpenseCataloguesQuery,
  useFetchSubmitedExpensesQuery,
  useSaveRequestWizardStep3V1Mutation,
  useSaveRequestWizardStep3Mutation,
  useFetchPastCaseExpensesQuery,
  useFetchRequestCaseWizardQuery,
  useSubmitRequestWizardStep4V1Mutation,
  useSubmitRequestWizardStep4Mutation,
  useLoadActivitiesAndAgentsQuery,
  useSaveRequestWizardStep2Mutation,
  useSaveRequestWizardStep2V1Mutation,
  useLoadActivitiesExpensesStepQuery,
  useLoadAgentsQuery,
  useSaveActivitiesExpensesStepMutation
} = buyerDaToolRequestWizardApi;
