import { versionedWebStorage } from '@common';
import {
  createEntityAdapter,
  EntityState,
  isAnyOf,
  ListenerMiddlewareInstance
} from '@reduxjs/toolkit';
import { rootReducer } from '../../reducers';
import { selectAuthProfile } from '../user';
import {
  enhancedSofApi,
  getSigningStatusKey,
  isSigningStatusAction
} from './graphql';
import { SigningResponseDto, Sof, SofEvent } from './graphql/generated';
import { statementOfFactsApi } from './rest/statementOfFactsApi';

const adapter = createEntityAdapter<Pick<SofEvent, 'id' | 'timestamp'>>({
  selectId: (e) => e.id
});

export const registerSofListeners = (
  listenerMiddleware: ListenerMiddlewareInstance<ReturnType<typeof rootReducer>>
) => {
  listenerMiddleware.startListening({
    matcher: enhancedSofApi.endpoints.getSofs.matchFulfilled,
    effect: (action, { getState }) => {
      const portCallIds =
        action.meta.arg.originalArgs.ids instanceof Array
          ? action.meta.arg.originalArgs.ids
          : [action.meta.arg.originalArgs.ids];

      const profile = selectAuthProfile(getState());

      if (!profile) {
        return;
      }

      for (const portCallId of portCallIds) {
        const query =
          getState()['sof-api'].queries[
            action.meta.arg.queryCacheKey.toString()
          ];
        if (!query) {
          continue;
        }
        const sof = (query.data as EntityState<Sof>).entities[portCallId];
        if (typeof sof === 'undefined') {
          continue;
        }

        const eventEntities = adapter.addMany(
          adapter.getInitialState(),
          sof.events
            .filter((e): e is SofEvent => e !== null)
            .map(({ id, timestamp }) => ({ id, timestamp }))
        );

        versionedWebStorage.setItem(
          `sof_${portCallId}_events_${profile.id}`,
          eventEntities
        );
      }
    }
  });

  listenerMiddleware.startListening({
    matcher: enhancedSofApi.endpoints.getSofs.matchFulfilled,
    effect: (_action, { dispatch }) => {
      dispatch(
        statementOfFactsApi.util.invalidateTags([
          'SOF_SIGNATURE_DATA',
          'SOF_TIMESHEET'
        ])
      );
    }
  });

  listenerMiddleware.startListening({
    matcher: isAnyOf(
      statementOfFactsApi.endpoints.resendMasterInvite.matchFulfilled,
      statementOfFactsApi.endpoints.uploadSofEventAttachment.matchFulfilled,
      statementOfFactsApi.endpoints.deleteSofEventAttachment.matchFulfilled
    ),
    effect: (_action, { dispatch }) => {
      dispatch(enhancedSofApi.util.invalidateTags(['SOF']));
    }
  });

  listenerMiddleware.startListening({
    predicate: (action, currentState, previousState) => {
      if (!isSigningStatusAction(action)) {
        return false;
      }
      const args = action.meta.arg.originalArgs;
      const signinStatusKey = getSigningStatusKey(args);

      const previousSigningStage =
        (
          previousState['sof-api'].queries[signinStatusKey]?.data as
            | SigningResponseDto
            | undefined
        )?.signingStage ?? '';

      const currentSigningStage =
        (
          currentState['sof-api'].queries[signinStatusKey]?.data as
            | SigningResponseDto
            | undefined
        )?.signingStage ?? '';

      if (!previousSigningStage || !currentSigningStage) {
        return false;
      }
      return previousSigningStage !== currentSigningStage;
    },
    effect: (action, { dispatch }) => {
      if (!isSigningStatusAction(action)) {
        return;
      }

      const portCallId = action.meta.arg.originalArgs.portCallId;

      dispatch(
        enhancedSofApi.util.invalidateTags([{ type: 'SOF', id: portCallId }])
      );
    }
  });
};
