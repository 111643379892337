import { axiosBaseQuery, createAxiosInstance } from '@client';
import { getEnvironmentVariables } from '@common';
import { S3ObjectResponse } from '@module/bank-account/model/S3ObjectResponse';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { HlAiBankAccountDetails } from '@state';

const { HL_AI_API_URL } = getEnvironmentVariables();

const axiosHarborlabAiInstance = createAxiosInstance({
  baseURLParam: `${HL_AI_API_URL}`
});

export const harborlabAiApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: '',
    customAxiosInstance: axiosHarborlabAiInstance
  }),
  reducerPath: 'harborlab-ai',
  endpoints: (build) => ({
    extractBankAccountDetails: build.mutation<
      HlAiBankAccountDetails,
      S3ObjectResponse
    >({
      query: (payload) => ({
        url: '/bank-account-details',
        method: 'POST',
        data: payload
      })
    })
  })
});

export type StateWithFormulaExecutionApi = {
  [harborlabAiApi.reducerPath]: ReturnType<typeof harborlabAiApi.reducer>;
};

export const { useExtractBankAccountDetailsMutation } = harborlabAiApi;
