import {
  PERSIST_KEY,
  SESSION,
  getEnvironmentVariables,
  webStorage
} from '@common';
import { logout } from './restfull-client';

export interface CustomResponse {
  status: number;
  ok: boolean;
  json: { message: string };
}

const env = getEnvironmentVariables();

export const context = {
  api: env.API_URL,
  path: '/da-app',
  pathTmaIntg: '/integration-veson-tma/api',
  pathOldfIntg: '/integration-veson-oldf/api',
  pathSalhIntg: '/integration-veson-salh/api',
  pathGescoIntg: '/integration-veson-gesco/api',
  pathStarbulkIntg: '/integration-veson-starbulk/api',
  pathSandboxIntg: '/integration-veson-sandbox/api',
  pathBaltnavIntg: '/integration-veson-baltnav/api',
  pathCmbIntg: '/integration-veson-cmb/api',
  pathEpsIntg: '/integration-veson-eps/api',
  pathMontFortIntg: '/integration-veson-mnft/api',
  s3PublicUrl: env.S3_PUBLIC_URL
};

export const usePsFetch = () => {
  const networkErrorResponse = (error: any) => {
    return {
      status: error.status,
      ok: false,
      json: { message: error.message }
    };
  };

  const request = (method: string, data?: any): RequestInit => {
    const session = webStorage.getItem<{ token: string }>(SESSION);
    return {
      body: data, // must match 'Content-Type' header
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, same-origin, *omit
      headers: {
        'content-type': 'application/json',
        ...(session ? { Authorization: `Bearer ${session?.token}` } : {})
      },
      method: method, // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // manual, *follow, error
      referrer: 'client' // *client, no-referrer
    };
  };

  const upload_headers = (
    data: any,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | undefined = 'POST'
  ): RequestInit => {
    const session = webStorage.getItem<{ token: string }>(SESSION);
    return {
      body: data, // must match 'Content-Type' header
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, same-origin, *omit
      headers: {
        ...(session ? { Authorization: `Bearer ${session?.token}` } : {})
      },
      method, // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      redirect: 'follow', // manual, *follow, error
      referrer: 'client' // *client, no-referrer
    };
  };

  const parseResponse = (response: Response): Promise<CustomResponse> => {
    return new Promise((resolve) => {
      if (response.status === 401) {
        response.json().then((json: any) => {
          if (!response.url.endsWith('logout')) {
            webStorage.removeItem(SESSION);
            webStorage.removeItem(PERSIST_KEY);
            logout();
          }
          resolve({
            status: response.status,
            ok: response.ok,
            json: {
              message: 'Full authentication is required to access this resource'
            }
          });
        });
        return;
      } else if (response.status === 403) {
        document.location.href = '/error-no-access';
      } else if (response.status === 404) {
        document.location.href = '/error-not-found';
      }

      response
        .json()
        .then((json: any) => {
          resolve({
            status: response.status,
            ok: response.ok,
            json: json
          });
        })
        .catch((e) => {
          console.log({ e });
          resolve({
            status: response.status,
            ok: response.ok,
            json: { message: '' }
          });
        });
    });
  };

  const getFileNameFromResponse = (response: Response, filename: string) => {
    let contentTypeHeader = response.headers.get('Content-Type');
    if (contentTypeHeader === null) {
      return filename;
    }

    let splitSemicolon = contentTypeHeader.split(';');
    if (splitSemicolon.length < 2) {
      return filename;
    }

    let splitEquals = splitSemicolon[1].trim().split('=');
    if (splitEquals.length < 2) {
      return filename;
    }

    let resolveFileName = splitEquals[1].replace(/"/g, '');
    if (resolveFileName && 'null' !== resolveFileName) {
      return resolveFileName;
    }
    return filename;
  };

  const get = <T>(uri: string, path = 'path'): Promise<T | any> => {
    return new Promise((resolve, reject) =>
      // @ts-ignore
      fetch(context.api + context[path] + uri, request('GET'))
        .then(parseResponse)
        .then((response: CustomResponse) => {
          if (response.ok) {
            return resolve(response.json);
          }
          return reject(response.json.message);
        })
        .catch((error) => {
          reject(networkErrorResponse(error));
        })
    );
  };

  const post = <T>(uri: string, data: any, path = 'path'): Promise<T | any> => {
    return new Promise((resolve, reject) =>
      fetch(
        // @ts-ignore
        context.api + context[path] + uri,
        request('POST', JSON.stringify(data))
      )
        .then(parseResponse)
        .then((response: CustomResponse) => {
          if (response.ok) {
            return resolve(response.json);
          }
          // extract the error from the server's json
          return reject(response.json.message);
        })
        .catch((error) => {
          console.log({ error });
          reject(networkErrorResponse(error));
        })
    );
  };

  const put = <T>(
    uri: string,
    data: any,
    path: keyof typeof context = 'path'
  ): Promise<T | any> => {
    return new Promise((resolve, reject) =>
      fetch(
        // @ts-ignore
        context.api + context[path] + uri,
        request('PUT', JSON.stringify(data))
      )
        .then(parseResponse)
        .then((response: CustomResponse) => {
          if (response.ok) {
            return resolve(response.json);
          }
          // extract the error from the server's json
          return reject(response.json.message);
        })
        .catch((error) => reject(networkErrorResponse(error)))
    );
  };

  const postAuth = <T>(uri: string, data: any): Promise<T | any> => {
    return new Promise((resolve, reject) =>
      fetch(
        // @ts-ignore
        'https://' + env.AUTH0_DOMAIN + uri,
        request('POST', JSON.stringify(data))
      )
        .then(parseResponse)
        .then((response: CustomResponse) => {
          if (response.ok) {
            return resolve(response.json);
          }
          // extract the error from the server's json
          return reject(response.json.message);
        })
        .catch((error) => reject(networkErrorResponse(error)))
    );
  };

  const multipart = <T>(
    uri: string,
    data?: object,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | undefined = 'POST'
  ): Promise<T | any> => {
    return new Promise((resolve, reject) =>
      fetch(context.api + context.path + uri, upload_headers(data, method))
        .then(parseResponse)
        .then((response: CustomResponse) => {
          if (response.ok) {
            return resolve(response.json);
          }
          // extract the error from the server's json
          return reject(response.json.message);
        })
        .catch((error) => reject(networkErrorResponse(error)))
    );
  };

  const download = (
    uri: string,
    filename: string,
    contentType?: string
  ): Promise<void> => {
    return new Promise((resolve, reject) =>
      fetch(context.api + context.path + uri, request('GET'))
        .then((response) => {
          if (response.status !== 200) throw response;
          filename = getFileNameFromResponse(response, filename);
          return response.blob();
        })
        // @ts-ignore
        .then((blob) => resolve(downloadFile(blob, filename, contentType)))
        .catch((error) => {
          console.log(error);
          window.open('/file-not-found');
          reject(networkErrorResponse(error));
        })
    );
  };

  const downloadPortAnalysis = (
    api: string,
    uri: string,
    filename: string,
    contentType?: string
  ): Promise<void> => {
    return new Promise((resolve, reject) =>
      fetch(api + '/port-analysis' + uri, request('GET'))
        .then((response) => {
          if (response.status !== 200) throw response;
          filename = getFileNameFromResponse(response, filename);
          return response.blob();
        })
        // @ts-ignore
        .then((blob) => resolve(downloadFile(blob, filename, contentType)))
        .catch((error) => {
          console.log(error);
          window.open('/file-not-found');
          reject(networkErrorResponse(error));
        })
    );
  };

  const downloadWithData = (
    uri: string,
    data: any,
    filename: string,
    contentType?: string
  ): Promise<void> => {
    return new Promise((resolve, reject) =>
      fetch(
        context.api + context.path + uri,
        request('POST', JSON.stringify(data))
      )
        .then((response) => {
          if (response.status !== 200) throw response;
          filename = getFileNameFromResponse(response, filename);
          return response.blob();
        })
        // @ts-ignore
        .then((blob) => resolve(downloadFile(blob, filename, contentType)))
        .catch((error) => {
          console.log(error);
          window.open('/file-not-found');
          reject(networkErrorResponse(error));
        })
    );
  };

  const downloadFile = (
    data: any,
    strFileName: string,
    strMimeType: string | undefined
  ) => {
    let self = window, // this script is only for browsers anyway...
      defaultMime = 'application/octet-stream', // this default mime also triggers iframe downloads
      mimeType = strMimeType || defaultMime,
      payload = data,
      url = !strFileName && !strMimeType && payload,
      anchor = document.createElement('a'),
      toString = function (a: any) {
        return String(a);
      },
      // @ts-ignore
      myBlob = self.Blob || self.MozBlob || self.WebKitBlob || toString,
      fileName = strFileName || 'download',
      blob,
      reader;
    // @ts-ignore
    myBlob = myBlob.call ? myBlob.bind(self) : Blob;

    // @ts-ignore
    if (String(this) === 'true') {
      //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
      payload = [payload, mimeType];
      mimeType = payload[0];
      payload = payload[1];
    }

    if (url && url.length < 2048) {
      // if no filename and no mime, assume a url was passed as the only argument
      fileName = url.split('/').pop().split('?')[0];
      anchor.href = url; // assign href prop to temp anchor
      if (anchor.href.indexOf(url) !== -1) {
        // if the browser determines that it's a potentially valid url path:
        var ajax = new XMLHttpRequest();
        ajax.open('GET', url, true);
        ajax.responseType = 'blob';
        ajax.onload = function (e) {
          // @ts-ignore
          download(e.target.response, fileName, defaultMime);
        };
        setTimeout(function () {
          ajax.send();
        }, 0); // allows setting custom ajax headers using the return:
        return ajax;
      } // end if valid url?
    } // end if url?

    //go ahead and download dataURLs right away
    if (/^data\:[\w+\-]+\/[\w+\-]+[,;]/.test(payload)) {
      // @ts-ignore
      if (payload.length > 1024 * 1024 * 1.999 && myBlob !== toString) {
        payload = dataUrlToBlob(payload);
        mimeType = payload.type || defaultMime;
      } else {
        // @ts-ignore
        return navigator.msSaveBlob // IE10 can't do a[download], only Blobs:
          ? // @ts-ignore
            navigator.msSaveBlob(dataUrlToBlob(payload), fileName)
          : // @ts-ignore
            saver(payload); // everyone else can save dataURLs un-processed
      }
    } //end if dataURL passed?

    blob =
      payload instanceof myBlob
        ? payload
        : new myBlob([payload], { type: mimeType });

    // @ts-ignore
    function dataUrlToBlob(strUrl) {
      var parts = strUrl.split(/[:;,]/),
        type = parts[1],
        decoder = parts[2] == 'base64' ? atob : decodeURIComponent,
        binData = decoder(parts.pop()),
        mx = binData.length,
        i = 0,
        uiArr = new Uint8Array(mx);

      for (i; i < mx; ++i) uiArr[i] = binData.charCodeAt(i);

      return new myBlob([uiArr], { type: type });
    }

    // @ts-ignore
    function saver(url, winMode) {
      if ('download' in anchor) {
        //html5 A[download]
        anchor.href = url;
        anchor.setAttribute('download', fileName);
        anchor.className = 'download-js-link';
        anchor.innerHTML = 'downloading...';
        anchor.style.display = 'none';
        document.body.appendChild(anchor);
        setTimeout(function () {
          anchor.click();
          document.body.removeChild(anchor);
          if (winMode === true) {
            setTimeout(function () {
              self.URL.revokeObjectURL(anchor.href);
            }, 250);
          }
        }, 66);
        return true;
      }

      // handle non-a[download] safari as best we can:
      if (
        /(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(
          navigator.userAgent
        )
      ) {
        url = url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
        if (!window.open(url)) {
          // popup blocked, offer direct download:
          if (
            confirm(
              'Displaying New Document\n\nUse Save As... to download, then click back to return to this page.'
            )
          ) {
            location.href = url;
          }
        }
        return true;
      }

      //do iframe dataURL download (old ch+FF):
      let f = document.createElement('iframe');
      document.body.appendChild(f);

      if (!winMode) {
        // force a mime that will download:
        url = 'data:' + url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
      }
      f.src = url;
      setTimeout(function () {
        document.body.removeChild(f);
      }, 333);
    } //end saver
    // @ts-ignore
    if (navigator.msSaveBlob) {
      // IE10+ : (has Blob, but not a[download] or URL)
      // @ts-ignore
      return navigator.msSaveBlob(blob, fileName);
    }

    if (self.URL) {
      // simple fast and modern way using Blob and URL:
      saver(self.URL.createObjectURL(blob), true);
    } else {
      // handle non-Blob()+non-URL browsers:
      if (typeof blob === 'string' || blob.constructor === toString) {
        try {
          // @ts-ignore
          return saver('data:' + mimeType + ';base64,' + self.btoa(blob));
        } catch (y) {
          // @ts-ignore
          return saver('data:' + mimeType + ',' + encodeURIComponent(blob));
        }
      }

      // Blob but not URL support:
      reader = new FileReader();
      reader.onload = function (e) {
        // @ts-ignore
        saver(this.result);
      };
      reader.readAsDataURL(blob);
    }
    return true;
  };

  return {
    get: get,
    post: post,
    put: put,
    postAuth: postAuth,
    multipart: multipart,
    download: download,
    downloadPortAnalysis: downloadPortAnalysis,
    downloadWithData: downloadWithData,
    request: request
  };
};
