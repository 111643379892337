import { ExpenseEstimationResponse } from '../../types/ExpenseEstimationResponse';
import { portAnalysisApi } from './portAnalysisServiceApi';
import { ExpenseRangeResponse } from '@state/types/ExpenseRangeResponse';
import { FormulaDto } from '@containers/admin/port-analysis/Expenses/Estimations/common/FormulaRangeFormValues';
import { PsUtilities } from 'components';

const BASE_URL = '/secured/expense-estimation';

const abstractEstimationApi = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    getEstimation: build.query<
      ExpenseEstimationResponse | ExpenseRangeResponse,
      number
    >({
      query: (estimationId) => ({
        url: `${BASE_URL}/${estimationId}`,
        method: 'get'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [
              {
                type: 'PORT_ANALYSIS_EXPENSE_ESTIMATIONS' as const,
                id: res.expenseId
              }
            ]
          : ['PORT_ANALYSIS_EXPENSE_ESTIMATION', 'PORT_ANALYSIS_EXPENSE_RANGE']
    }),
    deleteEstimation: build.mutation<boolean, number>({
      query: (estimationId) => ({
        url: `${BASE_URL}/${estimationId}`,
        method: 'delete'
      }),
      invalidatesTags: (res, _error, arg) => [
        'PORT_ANALYSIS_EXPENSE_ESTIMATIONS',
        'PORT_ANALYSIS_EXPENSES',
        'PORT_ANALYSIS_ESTIMATION_HISTORY'
      ]
    }),
    duplicateEstimation: build.mutation<
      ExpenseEstimationResponse | ExpenseRangeResponse,
      number
    >({
      query: (estimationId) => ({
        url: `${BASE_URL}/${estimationId}/duplicate`,
        method: 'POST'
      }),
      invalidatesTags: (res, _error, arg) =>
        res
          ? [
              {
                type: 'PORT_ANALYSIS_EXPENSE_ESTIMATIONS' as const,
                id: res.expenseId
              },
              ...res.locations
                .map((x) => x.id)
                .map((id) => ({
                  type: 'PORT_ANALYSIS_EXPENSES' as const,
                  id
                })),
              'PORT_ANALYSIS_ESTIMATION_HISTORY'
            ]
          : [
              'PORT_ANALYSIS_EXPENSE_ESTIMATIONS',
              'PORT_ANALYSIS_ESTIMATION_HISTORY',
              'PORT_ANALYSIS_EXPENSES'
            ]
    }),
    archiveEstimation: build.mutation<
      ExpenseEstimationResponse | ExpenseRangeResponse,
      number
    >({
      query: (estimationId) => ({
        url: `${BASE_URL}/${estimationId}/archive`,
        method: 'patch'
      }),
      invalidatesTags: (res, _error, arg) =>
        res
          ? [
              {
                type: 'PORT_ANALYSIS_EXPENSE_ESTIMATIONS' as const,
                id: res.expenseId
              },
              ...res.locations
                .map((x) => x.id)
                .map((id) => ({
                  type: 'PORT_ANALYSIS_EXPENSES' as const,
                  id
                })),
              'PORT_ANALYSIS_ESTIMATION_HISTORY'
            ]
          : [
              'PORT_ANALYSIS_EXPENSE_ESTIMATIONS',
              'PORT_ANALYSIS_ESTIMATION_HISTORY',
              'PORT_ANALYSIS_EXPENSES'
            ]
    }),
    publishEstimation: build.mutation<ExpenseEstimationResponse, number>({
      query: (estimationId) => ({
        url: `${BASE_URL}/${estimationId}/publish`,
        method: 'patch'
      }),
      invalidatesTags: (res, _error, arg) => [
        'PORT_ANALYSIS_ESTIMATION_HISTORY',
        'PORT_ANALYSIS_EXPENSE_ESTIMATIONS'
      ]
    }),
    schedulePublishEstimation: build.mutation<
      ExpenseEstimationResponse,
      [number, Date]
    >({
      query: ([estimationId, scheduleDate]) => ({
        url: `${BASE_URL}/${estimationId}/schedule_publish`,
        method: 'patch',
        params: {
          schedule_date: scheduleDate
        }
      }),
      invalidatesTags: (res, _error, arg) => [
        'PORT_ANALYSIS_ESTIMATION_HISTORY',
        'PORT_ANALYSIS_EXPENSE_ESTIMATIONS'
      ]
    }),
    getEstimationHistory: build.query<
      ExpenseEstimationResponse[] | ExpenseRangeResponse[],
      number
    >({
      query: (expenseId) => ({
        method: 'GET',
        url: `${BASE_URL}/${expenseId}/history`
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [
              {
                type: 'PORT_ANALYSIS_ESTIMATION_HISTORY' as const,
                id: arg
              }
            ]
          : ['PORT_ANALYSIS_ESTIMATION_HISTORY']
    })
  }),
  overrideExisting: false
});

export const {
  useArchiveEstimationMutation,
  useGetEstimationHistoryQuery,
  useGetEstimationQuery,
  useDeleteEstimationMutation,
  useDuplicateEstimationMutation,
  usePublishEstimationMutation,
  useSchedulePublishEstimationMutation
} = abstractEstimationApi;
