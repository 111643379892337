import { PortDirectoryHeaderDTO } from '@state/types/PortDirectoryHeaderDTO';
import { ContactCardDetails } from '@state/types/ContactCardDetails';
import { portAnalysisApi } from './portAnalysisServiceApi';
import {
  PortInformationDTO,
  PortOverviewDto,
  PortOverviewInfo
} from '@state/types/PortInformationDTO';
import { PortDirectoryAdditionalInformationDTO } from '@state/types/PortDirectoryAdditionalInformationDTO';
import { PortDirectoryPortDTO } from '@state/types/PortDirectoryPortDTO';
import { HarborComplexDTO } from '@state/types/HarborComplexDTO';

const BASE_URL = '/port-directory';

const portDirectoryAPI = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    fetchPortDirectoryHeader: build.query<PortDirectoryHeaderDTO, number>({
      query: (portId) => ({
        url: `${BASE_URL}/header/port/${portId}`,
        method: 'GET'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_DIRECTORY_HEADER' as const, id: arg }]
          : ['PORT_DIRECTORY_HEADER']
    }),
    fetchSuppliers: build.query<ContactCardDetails[], number>({
      query: (portId) => ({
        url: `${BASE_URL}/supplier/port/${portId}`,
        method: 'GET'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_DIRECTORY_SUPPLIERS' as const, id: arg }]
          : ['PORT_DIRECTORY_SUPPLIERS']
    }),
    fetchPortInformation: build.query<PortInformationDTO, number>({
      query: (portId) => ({
        url: `${BASE_URL}/port-information/port/${portId}`,
        method: 'GET'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_INFORMATION' as const, id: arg }]
          : ['PORT_INFORMATION']
    }),
    fetchPortDirectoryPort: build.query<PortDirectoryPortDTO, string>({
      query: (portName) => ({
        url: `${BASE_URL}/port/${portName}`,
        method: 'GET'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_DIRECTORY_PORT' as const, portName: arg }]
          : ['PORT_DIRECTORY_PORT']
    }),
    fetchAiDirectoryPortSearch: build.mutation<string[], { prompt: string }>({
      query: ({ prompt }) => ({
        url: `${BASE_URL}/ai/port/search`,
        data: prompt,
        method: 'POST'
      })
    }),
    fetchPortAdditionalInformation: build.query<
      PortDirectoryAdditionalInformationDTO,
      number
    >({
      query: (portId) => ({
        url: `${BASE_URL}/port-information/port/${portId}/additional-information`,
        method: 'GET'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_DIRECTORY_ADDITIONAL_INFO' as const, id: arg }]
          : ['PORT_DIRECTORY_ADDITIONAL_INFO']
    }),
    fetchHarborComplex: build.query<
      HarborComplexDTO,
      {
        portId: number;
        vesselType?: string;
        cargo?: string;
        facility?: string;
      }
    >({
      query: ({ portId, ...params }) => {
        return {
          url: `${BASE_URL}/harbor-complex/port/${portId}`,
          method: 'GET',
          params
        };
      },
      transformResponse: (response: Partial<HarborComplexDTO>) => ({
        segments: response.segments || [],
        cargos: response.cargos || [],
        facilities: response.facilities || [],
        harborComplexTerminals: response.harborComplexTerminals || [],
        harborComplexAnchorages: response.harborComplexAnchorages || []
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_DIRECTORY_HARBOR_COMPLEX' as const, id: arg.portId }]
          : ['PORT_DIRECTORY_HARBOR_COMPLEX']
    }),
    fetchPortOverview: build.query<PortOverviewDto, number>({
      query: (portId) => ({
        url: `${BASE_URL}/overview/port/${portId}`,
        method: 'GET'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_DIRECTORY_OVERVIEW' as const, id: arg }]
          : ['PORT_DIRECTORY_OVERVIEW']
    })
  }),
  overrideExisting: false
});

export const {
  useFetchPortDirectoryHeaderQuery,
  useLazyFetchPortDirectoryHeaderQuery,
  useFetchPortDirectoryPortQuery,
  useLazyFetchPortDirectoryPortQuery,
  useFetchPortAdditionalInformationQuery,
  useFetchSuppliersQuery,
  useFetchHarborComplexQuery,
  useFetchPortInformationQuery,
  useFetchAiDirectoryPortSearchMutation,
  useFetchPortOverviewQuery
} = portDirectoryAPI;
