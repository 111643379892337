import { PhoneResponse } from '@module/contact/model/PhoneResponse';
import { SupplierResponse } from '@module/supplier/model/SupplierResponse';
import { SupplierSubscription } from '@module/supplier/model/SupplierSubscription';
import { mainRestApi } from '../rest-api';

export interface SupplierProfileForm {
  id?: string;
  username?: string;
  name: string;
  surname: string;
  email: string;
}

interface AgentRegistrationSupplier extends SupplierResponse {
  contact: {
    phone1: PhoneResponse & {
      countryId: number;
      number: string;
      fullNumber: '+73216549870';
    };
    phone2?: PhoneResponse & {
      countryId: number;
      number: string;
      fullNumber: string;
    };
  };
  worldwide: boolean;
}

export const supplierApi = mainRestApi.injectEndpoints({
  endpoints: (build) => ({
    saveSupplierProfileSettings: build.mutation<
      SupplierProfileForm,
      SupplierProfileForm
    >({
      query: (data) => ({
        url: '/secured/supplier/settings/profile/save',
        method: 'POST',
        data
      })
    }),
    loadSupplierDetails: build.query<AgentRegistrationSupplier, void>({
      query: () => ({
        url: '/secured/supplier/load',
        method: 'GET'
      })
    }),
    loadSupplierSubscription: build.query<SupplierSubscription, void>({
      query: () => ({
        url: '/secured/supplier/setting/profile/subscription',
        method: 'GET'
      })
    })
  }),
  overrideExisting: true
});

export const {
  useSaveSupplierProfileSettingsMutation,
  useLoadSupplierDetailsQuery,
  useLoadSupplierSubscriptionQuery
} = supplierApi;

export const loadSupplierRequestCompletedMatcher =
  supplierApi.endpoints['loadSupplierDetails'].matchFulfilled;
