import React, { PropsWithChildren, useImperativeHandle, useState } from 'react';
import { PsModalHandler } from '../handlers';
import { StyleLoadingAttributes } from '../types';
import PsButton from './Button/Button';
import useKeyHandler from './ps-key-handler';
import PsLoadingPlaceholder from './ps-loading-placeholder';

interface Props extends StyleLoadingAttributes, PropsWithChildren {
  title?: string;
  isOpened?: boolean;
  isLarge?: boolean;
  isLoading?: boolean;
  onClose?: any;
  loadingTemplate?: string;
  closeButtonName?: string;
  actions?: React.ReactNode;
  minHeight?: string;
  hasHeader?: boolean;
  isSmall?: boolean;
  ref: any;
}

const PsModal: React.FunctionComponent<Props> = React.forwardRef(
  (
    {
      children,
      title,
      isOpened = false,
      isLarge = false,
      isSmall = false,
      onClose,
      loadingTemplate = PsLoadingPlaceholder.tableTemplate(),
      closeButtonName = 'Close',
      actions = null,
      minHeight = '200px',
      hasHeader = true,
      ...rest
    },
    ref: React.Ref<PsModalHandler>
  ) => {
    const [isActive, setIsActive] = useState(isOpened);
    const [isLoading, setIsLoading] = useState(rest.isLoading);

    const close = () => {
      setIsActive(false);
      if (!!onClose) onClose();
    };

    useKeyHandler(document, 'Escape', 'keydown', close);

    const open = () => {
      setIsLoading(true);
      setIsActive(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    };

    useImperativeHandle(ref, () => {
      return {
        open: open,
        close: close
      };
    });

    return (
      <div
        className={
          'modal' +
          (isActive ? ' is-active' : '') +
          (isLarge ? ' is-large' : '')
        }
      >
        <div className="modal-background" />
        <div
          className={`modal-card ${isSmall && 'is-small-modal'}`}
          data-testid={`modal-${title?.replace(/\s+/g, '')}`}
        >
          {hasHeader && (
            <header className="modal-card-head">
              <p className="modal-card-title">{title}</p>
              <button
                type="button"
                className="delete"
                aria-label="close"
                onClick={close}
              />
            </header>
          )}

          <React.Fragment>
            <section
              className="modal-card-body"
              style={{ minHeight: minHeight, minWidth: 50 }}
            >
              {isLoading
                ? PsLoadingPlaceholder.parse(loadingTemplate)
                : isActive
                ? children
                : null}
            </section>
            <footer className="modal-card-foot">
              <div className={`buttons ${isSmall && 'small-modal-buttons'}`}>
                <PsButton onClick={close}>{closeButtonName}</PsButton>
                {actions}
              </div>
            </footer>
          </React.Fragment>
        </div>
      </div>
    );
  }
);

export default PsModal;
