import React, { useEffect } from 'react';
import FrontEndFooter from '../../fragment/FrontEndFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import {
  addBodyControlPanelClasses,
  setPageTitle
} from '../../../_common/utilities/ui';
import { Link } from 'react-router-dom';

const MaintenancePage = React.memo(() => {
  useEffect(() => {
    addBodyControlPanelClasses();
    setPageTitle();
  });

  return (
    <React.Fragment>
      <header className="hero">
        <div className="hero-head">
          <nav
            className="navbar is-white"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="container">
              <div className="navbar-brand">
                <Link
                  className="navbar-item"
                  to="/home"
                  title="Harbor Lab - Reshaping Ports, Side by Side"
                >
                  <img
                    src="/img/logo/main_logo.svg"
                    className="logo"
                    alt="Harbor Lab logo"
                  />
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <main>
        <section className="section is-medium">
          <div className="container">
            <div className="columns is-centered is-vertical">
              <div className="column is-8-tablet is-5-desktop">
                <div className="card has-radius has-shadow-xlarge">
                  <div className="card-content">
                    <p className="title">
                      <span className="icon is-large has-text-info top-2 mr-3">
                        <FontAwesomeIcon icon={faCogs} />
                      </span>
                      <span>Maintenance!</span>
                    </p>
                    <p className="s-m-bottom">
                      Our services are being upgraded. We'll be back online
                      before you know it. Thank you for your patience!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FrontEndFooter />
    </React.Fragment>
  );
});

export default MaintenancePage;
