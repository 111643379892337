import React from 'react';
import { RefData } from 'components';
import { AppointmentResponse } from '../../../module/appointment/model/AppointmentResponse';

export const addBodyControlPanelClasses = (noPadding: boolean = false) => {
  document.getElementById('body')!.classList.add('cp');
  if (noPadding)
    document.getElementById('body')!.classList.add('is-paddingless');
};

export const removeBodyControlPanelClasses = () => {
  document.getElementById('body')!.classList.remove('cp', 'is-paddingless');
};

export const removeIsPaddingLessClass = () => {
  document.getElementById('body')!.classList.remove('is-paddingless');
};

export const setPageTitle = (
  title: string = 'Harbor Lab - Reshaping Ports, Side by Side'
) => {
  document.title = title;
};

export const findItem = (
  list: RefData[] | undefined,
  value: string
): RefData | undefined => {
  if (!list) return undefined;
  return list.find((i) => '' + i.value === '' + value);
};

export const printAppointmentTitle = (appointment: AppointmentResponse) => {
  var title = '';
  if (appointment.vessel) title += appointment.vessel.label + ' ';
  return title + 'V.' + appointment.voyage + ' at ' + appointment.port.name;
};

export const getShowOfPossession = (word: string) => {
  if (word.endsWith('s')) {
    return "'";
  }
  return "'s";
};

export const subDays = (date: Date, days: number) => {
  var d = new Date(date);
  d.setDate(date.getDate() - days);
  return d;
};

export const renderApprovalStateTag = (state: string) => {
  switch (state) {
    case 'PENDING':
      return (
        <div className="tag is-medium has-small-min-width has-letter-spacing is-warning">
          PENDING
        </div>
      );
    case 'REJECTED':
      return (
        <div className="tag is-medium has-small-min-width has-letter-spacing is-danger">
          REJECTED
        </div>
      );
    case 'APPROVED':
      return (
        <div className="tag is-medium has-small-min-width has-letter-spacing is-success">
          APPROVED
        </div>
      );
    default:
      return (
        <div className="tag is-medium has-small-min-width has-letter-spacing">
          -
        </div>
      );
  }
};

export const renderPortCallStateTag = (state: string) => {
  switch (state) {
    case 'IMPORTED':
      return (
        <div className="tag has-small-min-width has-letter-spacing is-primary is-light is-medium top-2">
          IMPORTED
        </div>
      );
    case 'DRAFT':
      return (
        <div className="tag has-small-min-width has-letter-spacing is-warning is-medium top-2">
          DRAFT
        </div>
      );
    case 'IN_PROCESS':
      return (
        <div className="tag has-small-min-width has-letter-spacing is-success is-medium top-2">
          IN PROCESS
        </div>
      );
    case 'CANCELLED':
      return (
        <div className="tag has-small-min-width has-letter-spacing is-danger is-medium top-2">
          CANCELLED
        </div>
      );
    default:
      return (
        <div className="tag has-small-min-width has-letter-spacing is-medium top-2">
          -
        </div>
      );
  }
};

export const breakString = (
  value: string,
  length: number = 14,
  defaultValue?: string
) => {
  if (!value || value == '') return defaultValue;
  if (value.length - 1 <= length) {
    return value;
  }
  return value.substring(0, length) + '...';
};

/**
 * Function removes thousand comma separators from value.
 * @param val
 */
export const cleanAmount = (val: string) => {
  if (!val) return val;
  return val.replace(/,/g, '');
};

export const inputClasses = (hasError: boolean) => {
  return 'input' + (hasError ? ' is-danger' : '');
};

export const errorClass = (hasError: boolean): string => {
  return hasError ? ' is-danger' : '';
};

export const hasError = (
  formErrors: Map<string, boolean> | null | undefined,
  key: string
): boolean => {
  return !!formErrors && formErrors.has(key);
};

export const hasErrors = (
  formErrors: Map<string, boolean> | null | undefined
): boolean => {
  return !!formErrors && formErrors.size > 0;
};

export const isBelowThreshold = (
  value: number,
  base: number,
  threshold: number
) => {
  if (value && base && threshold) {
    const result = (value / base) * 100;
    return Math.round(result) < threshold;
  }
  return false;
};
