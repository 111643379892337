import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './app/sitemap/App';
import './sass/main.scss';

ReactDOM.render(<App />, document.getElementById('root'));

let newWorker: ServiceWorker | null;

function showUpdateBar() {
  let snackbar = document.getElementById('snackbar');
  snackbar && (snackbar.className = 'show');
}

// Wait for the new service worker to activate, then reload
const onControllerChange = () => {
  window.location.reload();
  navigator.serviceWorker.removeEventListener(
    'controllerchange',
    onControllerChange
  );
};

// The click event on the pop up notification
document.getElementById('reload')?.addEventListener('click', function () {
  navigator.serviceWorker.addEventListener(
    'controllerchange',
    onControllerChange
  );

  newWorker?.postMessage({ action: 'skipWaiting' });
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistration().then((reg) => {
    if (!reg) {
      return;
    }
    reg.addEventListener('updatefound', () => {
      // A wild service worker has appeared in reg.installing!
      newWorker = reg.installing;

      newWorker?.addEventListener('statechange', () => {
        if (
          newWorker?.state === 'installed' &&
          navigator.serviceWorker.controller
        ) {
          showUpdateBar(); // Show the update prompt
        }
      });
    });
  });
}
