import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkedAlt';
import { faPhoneSquareAlt } from '@fortawesome/free-solid-svg-icons/faPhoneSquareAlt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { getEnvironmentVariables } from '@common';

const { COOKIES_CONSENT_ENABLED } = getEnvironmentVariables();

const FrontEndFooter = React.memo(() => {
  useEffect(() => {
    window.reloadOTBanner();
  }, []);

  return (
    <footer className="footer g-footer">
      <div className="container">
        <nav>
          <div className="columns">
            <div className="column is-4">
              <div className="vcard">
                <Link
                  to="/home"
                  className="footer-logo"
                  title="Harbor Lab - Reshaping Ports, Side by Side"
                >
                  <img
                    src="/img/logo/main_logo_white.svg"
                    className="logo"
                    alt="Harbor Lab logo"
                  />
                </Link>
                <p className="n-m-bottom">
                  Harbor Lab S.A.
                  <br />
                  Leof. Kifisias 278, 15232,
                  <br />
                  Athens Greece
                </p>
                <ul>
                  <li>
                    <span className="icon top-2">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    <a
                      href="mailto:operations@harborlab.com"
                      title="Send us an email"
                    >
                      operations@harborlab.com
                    </a>
                  </li>
                  <li>
                    <span className="icon top-2">
                      <FontAwesomeIcon icon={faPhoneSquareAlt} />
                    </span>
                    <a href="tel:+302122149874" title="Give us a call">
                      +30 212 214 9874
                    </a>
                  </li>
                  <li>
                    <span className="icon top-2">
                      <FontAwesomeIcon icon={faMapMarkedAlt} />
                    </span>
                    <a
                      target="_blank"
                      href="https://goo.gl/maps/hSgt7C8wrRghpxp29"
                    >
                      Location on map
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="column is-2 is-offset-1">
              <p className="heading is-size-7 has-text-weight-bold">Company</p>
              <ul>
                <li>
                  <Link to="/company" title="Company">
                    Company
                  </Link>
                </li>
                <li>
                  <Link to="/careers" title="Careers">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to="/articles" title="Articles">
                    Articles
                  </Link>
                </li>
                <li>
                  <Link to="/partnerships" title="Partnerships">
                    Partnerships
                  </Link>
                </li>
                <li>
                  <Link to="/faq" title="FAQ">
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link to="/contact" title="Contact us">
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="column is-2">
              <p className="heading is-size-7 has-text-weight-bold">Services</p>
              <ul>
                <li>
                  <Link to="/clients" title="Clients">
                    Clients
                  </Link>
                </li>
                <li>
                  <Link to="/solutions" title="Solutions">
                    Solutions
                  </Link>
                </li>
                <li>
                  <Link to="/authorization" title="Login to your account">
                    Login / Register
                  </Link>
                </li>
              </ul>
            </div>
            <div className="column is-2">
              <p className="heading is-size-7 has-text-weight-bold">Legal</p>
              <ul>
                <li>
                  <Link to="/privacy" title="Privacy Policy">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms" title="Terms & Conditions">
                    Terms &amp; Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/cookies" title="Cookie Policy">
                    Cookie Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="column is-1 has-text-right-desktop">
              <img
                src="/img/careers/gptw-certified-logo.svg"
                alt="Harbor Lab logo"
                style={{ maxWidth: '6rem' }}
              />
            </div>
          </div>
        </nav>
        <div className="content">
          <hr />
          <div className="columns is-mobile">
            <div className="column is-3">
              <p className="copy is-size-7">
                2021 &copy; Harbor Lab. All rights reserved
              </p>
            </div>
            {COOKIES_CONSENT_ENABLED && (
              <div className="column">
                <a className="ot-sdk-show-settings is-size-7">
                  Cookie Settings
                </a>
              </div>
            )}
            <div className="column has-text-right">
              <a
                href="https://www.linkedin.com/company/harbor-lab-/"
                target="_blank"
                title="Connect with Harbor Lab at Linkedin"
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faLinkedin} />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
});

export default FrontEndFooter;
