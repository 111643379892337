import { axiosBaseQuery, createAxiosInstance } from '@client';
import { getEnvironmentVariables } from '@common';
import { createApi } from '@reduxjs/toolkit/query/react';

const { FORMULA_EXECUTOR_API_OVERRIDE, API_URL } = getEnvironmentVariables();

const axiosFormulaExecutionInstance = createAxiosInstance({
  baseURLParam: `${FORMULA_EXECUTOR_API_OVERRIDE ?? API_URL}`
});

export const portAnalysisApi = createApi({
  reducerPath: 'port-analysis-microservice',
  baseQuery: axiosBaseQuery({
    baseUrl: '/port-analysis',
    customAxiosInstance: axiosFormulaExecutionInstance
  }),
  endpoints: () => ({}),
  tagTypes: [
    // port analysis tags
    'PORT_ANALYSIS_PORT',
    'PORT_ANALYSIS_LOCATION',
    'PORT_ANALYSIS_TERMINAL',
    'PORT_ANALYSIS_BERTH',
    'PORT_ANALYSIS_EXPENSE',
    'PORT_ANALYSIS_EXPENSES',
    'PORT_ANALYSIS_EXPENSE_ESTIMATION',
    'PORT_ANALYSIS_EXPENSE_ESTIMATIONS',
    'PORT_ANALYSIS_ESTIMATION_HISTORY',
    'PORT_ANALYSIS_EXPENSE_RANGE',
    'ADMIN_NOTES',
    // port directory tags
    'PORT_DIRECTORY_HEADER',
    'PORT_DIRECTORY_SUPPLIERS',
    'PORT_DIRECTORY_ADDITIONAL_INFO',
    'PORT_DIRECTORY_PORT',
    'PORT_DIRECTORY_HARBOR_COMPLEX',
    'PORT_DIRECTORY_OVERVIEW',
    'PORT_INFORMATION'
  ]
});
