import { axiosBaseQuery } from '@client';
import { ExpenseEvaluationFormulaResponse } from '@module/expense-evaluation/model/ExpenseEvaluationFormulaResponse';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  ClarificationsRequestDto,
  FormulaAnalyserFeedbackForm
} from './models';

export const expenseEvaluationFormulaApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'expense-evaluation-formula_api',
  endpoints: (build) => ({
    fetchExpenseEvaluationFormula: build.query<
      ExpenseEvaluationFormulaResponse,
      {
        evaluationId: string;
        expenseEvaluationId: number;
        exchangeRate?: number;
        forceClearCache: boolean;
      }
    >({
      query: ({
        evaluationId,
        expenseEvaluationId,
        exchangeRate,
        forceClearCache
      }) => ({
        url: `/secured/evaluation/${evaluationId}/expense/${expenseEvaluationId}/analyze`,
        method: 'GET',
        params: {
          exchangeRate: !!exchangeRate ? String(exchangeRate) : undefined,
          forceClearCache: String(forceClearCache)
        }
      })
    }),
    rateExpenseEvaluationFormula: build.mutation<
      void,
      FormulaAnalyserFeedbackForm
    >({
      query: (data) => ({
        url: `/secured/formula/analyzer/feedback`,
        method: 'POST',
        data
      })
    }),
    fetchEvaluationDiscrepancies: build.query<
      ExpenseEvaluationFormulaResponse[],
      { evaluationId: string }
    >({
      query: ({ evaluationId }) => ({
        url: `/secured/evaluation/${evaluationId}/analyze/discrepancies`,
        method: 'GET'
      })
    }),
    sendClarificationsRequest: build.mutation<
      void,
      { evaluationId: string; data: ClarificationsRequestDto }
    >({
      query: ({ evaluationId, data }) => ({
        url: `/secured/evaluation/${evaluationId}/clarifications-request/send`,
        method: 'POST',
        data
      })
    })
  })
});

export const {
  useLazyFetchExpenseEvaluationFormulaQuery,
  useRateExpenseEvaluationFormulaMutation,
  useLazyFetchEvaluationDiscrepanciesQuery,
  useSendClarificationsRequestMutation
} = expenseEvaluationFormulaApi;
