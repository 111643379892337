import { ExpenseEstimationResponse } from '../../types/ExpenseEstimationResponse';
import { portAnalysisApi } from './portAnalysisServiceApi';
import { FormulaTestingDTO } from '@state/types/FormulaTestingDTO';
import { PsUtilities } from 'components';
import { FormulaDto } from '@containers/admin/port-analysis/Expenses/Estimations/common/FormulaRangeFormValues';

const BASE_URL = '/secured/expense-estimation-formula';

const estimationApi = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    createFormula: build.mutation<
      ExpenseEstimationResponse,
      {
        form: FormulaDto;
        attachments: File[] | undefined;
      }
    >({
      query: ({ form, attachments }) => ({
        url: `${BASE_URL}`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: PsUtilities.createFormRequestParts(form, attachments),
        formData: true
      }),
      invalidatesTags: (res, _error, arg) => [
        'PORT_ANALYSIS_ESTIMATION_HISTORY',
        'PORT_ANALYSIS_EXPENSE_ESTIMATIONS'
      ]
    }),
    updateFormula: build.mutation<
      ExpenseEstimationResponse,
      {
        estimationId: number;
        form: FormulaDto;
        attachments: File[] | undefined;
      }
    >({
      query: ({ estimationId, form, attachments }) => ({
        url: `${BASE_URL}/${estimationId}`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: PsUtilities.createFormRequestParts(form, attachments),
        formData: true
      }),
      invalidatesTags: (res, _error, arg) => [
        'PORT_ANALYSIS_ESTIMATION_HISTORY',
        'PORT_ANALYSIS_EXPENSE_ESTIMATIONS'
      ]
    }),
    testFormula: build.mutation<any, FormulaTestingDTO>({
      query: (data) => ({
        url: `${BASE_URL}/test`,
        method: 'post',
        data: data
      })
    })
  }),
  overrideExisting: false
});

export const {
  useCreateFormulaMutation,
  useUpdateFormulaMutation,
  useTestFormulaMutation
} = estimationApi;
