import * as BaseFilePond from 'filepond';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FilePond } from 'react-filepond';
import { PsFileUploadHandler } from '../handlers';
import { FileItem } from '../types';
import usePsContext from './ps-context';
import usePsFetch from './ps-fetch';
import fileDownloadPlugin from './ps-file-upload-download-plugin';

// BaseFilePond.registerPlugin(FilePondPluginFileValidateType);
//todo: has a bug that displays ID than filename
BaseFilePond.registerPlugin(fileDownloadPlugin);
// BaseFilePond.registerPlugin(FilePondPluginFileValidateSize);
// BaseFilePond.registerPlugin(FilePondPluginImageValidateSize);

export interface Props {
  url?: string;
  initFiles?: FileItem[];
  allowMultiple?: boolean;
  disabled?: boolean;
  onAddFile?: (file: BaseFilePond.FilePondFile) => void;

  validateFileType?: boolean;
  acceptedFileTypes?: string[];

  disableFileSizeValidation?: boolean;
  maxFileSize?: number;
  maxTotalFileSize?: number;

  validateImageSize?: boolean;
  imageWidth?: number;
  imageHeight?: number;
  contextOverride?: { api: string; path: string };
}

const PsFileUpload = React.forwardRef<PsFileUploadHandler | null, Props>(
  (
    {
      url,
      initFiles,
      allowMultiple = true,
      disabled = false,
      onAddFile,
      validateFileType = false,
      acceptedFileTypes,
      disableFileSizeValidation = false,
      maxFileSize = 20971520,
      maxTotalFileSize = 26214400,
      validateImageSize = false,
      imageWidth = 100,
      imageHeight = 100,
      contextOverride
    },
    ref: React.Ref<PsFileUploadHandler>
  ) => {
    const [files, setFiles] = useState<any[]>([]);
    const pond = useRef<FilePond>(null);
    const fetch = usePsFetch();
    const context = contextOverride ?? usePsContext();

    useEffect(() => {
      if (files.length === 0) {
        init(initFiles ?? []);
      }
    }, [initFiles]);

    const handleUploadFiles = (files: any) => {
      setFiles(files);
    };

    useImperativeHandle(ref, () => {
      return {
        files: files,
        reset: () => setFiles([]),
        init: init,
        hasValidFileSize: hasValidFileSize()
      };
    });

    const init = (fileItems: FileItem[]) => {
      if (!!fileItems) {
        const files: any[] = fileItems.map((f) => ({
          filename: f.name,
          source: '' + f.id,
          options: { type: 'local' }
        }));
        setFiles(files);
      }
    };

    const hasValidFileSize = (): boolean => {
      if (!!files) {
        let totalSize = 0;
        for (const f of files) {
          totalSize += f.size;
          if (f.size > maxFileSize) return false;
        }
        if (totalSize > maxTotalFileSize) return false;
      }
      return true;
    };

    const server = () => {
      return !!url
        ? {
            url: context.api + context.path,
            timeout: 7000,
            load: {
              url,
              method: 'GET' as const,
              headers: fetch.request('GET' as const).headers as Record<
                string,
                string
              >,
              withCredentials: false
            },
            revert: null,
            restore: null,
            process: null,
            fetch: null
          }
        : null;
    };

    const isRemoveDisabled = () => {
      return !disabled;
    };
    return (
      <FilePond
        ref={pond}
        allowMultiple={allowMultiple}
        labelIdle={
          disabled
            ? ''
            : 'Drag & Drop your ' +
              (allowMultiple ? 'files' : 'file') +
              ' or <span class="filepond--label-action"> Browse </span>'
        }
        files={files}
        server={server()}
        onaddfile={(_e, file) => void onAddFile?.(file)}
        allowDrop={!disabled}
        allowBrowse={!disabled}
        allowPaste={!disabled}
        beforeRemoveFile={isRemoveDisabled}
        onupdatefiles={(fileItems) =>
          handleUploadFiles(fileItems.map((f) => f.file))
        }
        allowFileTypeValidation={validateFileType}
        acceptedFileTypes={acceptedFileTypes}
        allowFileSizeValidation={!disableFileSizeValidation}
        maxFileSize={maxFileSize + 'B'}
        maxTotalFileSize={maxTotalFileSize + 'B'}
        allowImageValidateSize={validateImageSize}
        imageValidateSizeMinWidth={imageWidth}
        imageValidateSizeMaxWidth={imageWidth}
        imageValidateSizeMinHeight={imageHeight}
        imageValidateSizeMaxHeight={imageHeight}
      />
    );
  }
);

export default PsFileUpload;
