import { useDisableBodyScroll } from '@common';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animated, useSpringRef, useTransition } from '@react-spring/web';
import clsx from 'clsx';
import { useKeyHandler } from 'components/components';
import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { createPortal } from 'react-dom';

export const DrawerContext = createContext<{
  isDrawerOpen: (id: string) => boolean;
  drawerDict: Record<string, boolean>;
  toggleDrawer: (id: string) => void;
  openDrawer: (id: string) => void;
  closeDrawer: (id: string) => void;
  setOnCloseCallback: (id: string, onClose: () => void) => void;
}>({
  isDrawerOpen: () => false,
  drawerDict: {},
  toggleDrawer: () => {},
  openDrawer: () => {},
  closeDrawer: () => {},
  setOnCloseCallback: () => {}
});

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  return context;
};

export const Drawer = ({
  children,
  title,
  subTitle,
  className,
  id,
  icon,
  width = 700,
  onClose
}: PropsWithChildren<{
  id: string;
  title?: string | undefined | ReactNode;
  subTitle?: ReactNode | undefined;
  className?: string | undefined;
  icon?: IconProp | undefined;
  width?: number;
  onClose?: () => void;
}>) => {
  const { isDrawerOpen, drawerDict, closeDrawer, setOnCloseCallback } =
    useDrawerContext();
  const transRef = useSpringRef();

  const transitions = useTransition(drawerDict[id], {
    from: { width: 0, opacity: 0 },
    enter: { width: width, opacity: 1 },
    leave: { width: 0, opacity: 0 }
  });

  useDisableBodyScroll(isDrawerOpen(id));

  useKeyHandler(document, 'Escape', 'keydown', () => {
    closeDrawer(id);
  });

  useEffect(() => {
    transRef.start({
      width: drawerDict[id] ? width : 0,
      opacity: drawerDict[id] ? 1 : 0
    });
  }, [drawerDict]);

  useEffect(() => {
    if (drawerDict[id] && onClose) {
      setOnCloseCallback(id, onClose);
    }
  }, [drawerDict[id]]);

  return transitions((style, isOpen) =>
    isOpen ? (
      createPortal(
        <>
          <animated.div
            onClick={() => {
              closeDrawer(id);
            }}
            className={clsx('drawer-background', className)}
            style={{ opacity: style.opacity }}
          />
          <animated.div
            className={clsx('slide-in-drawer', className)}
            style={{ width: style.width }}
          >
            <animated.div
              className="card"
              style={{
                padding: '1rem 2rem 1rem 2rem',
                opacity: style.opacity
              }}
            >
              <header className="pb-4 mb-4">
                <div className="is-flex is-align-items-center">
                  {icon && (
                    <span
                      className="card-header-icon mr-2"
                      style={{
                        width: '1.5rem',
                        height: '1.5rem'
                      }}
                    >
                      <FontAwesomeIcon icon={icon} size="xl" />
                    </span>
                  )}
                  <p className="card-header-title p-0 is-size-4 has-text-dark has-text-weight-semibold">
                    {title ?? 'Comments'}
                  </p>
                  <span
                    onClick={() => closeDrawer(id)}
                    className="card-header-icon p-0 icon has-text-dark is-clickable"
                    aria-label="close-drawer"
                    style={{
                      width: '2rem',
                      height: '2rem',
                      borderRadius: '1.5rem',
                      background: 'var(--hl-subtle-light)'
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} size="xl" />
                  </span>
                </div>
                {subTitle && (
                  <div className="has-text-weight-semibold">{subTitle}</div>
                )}
              </header>
              <div
                id="content"
                className="card-content p-0 is-flex is-flex-direction-column is-justify-content-space-between"
              >
                {children}
              </div>
            </animated.div>
          </animated.div>
        </>,
        document.body,
        id
      )
    ) : (
      <></>
    )
  );
};

export const DrawerProvider = ({ children }: PropsWithChildren) => {
  const [drawerDict, setDrawerDict] = useState<Record<string, boolean>>({});

  const onCloseCallbacksRef = useRef<Record<string, (() => void) | undefined>>(
    {}
  );

  const setOnCloseCallback = (id: string, onClose: () => void) => {
    onCloseCallbacksRef.current[id] = onClose;
  };

  const toggleDrawer = (id: string) => {
    setDrawerDict((prev) => ({ ...prev, [id]: !prev[id] }));
    if (!isDrawerOpen(id)) {
      if (onCloseCallbacksRef.current[id]) {
        onCloseCallbacksRef.current[id]!();
      }
    }
  };

  const isDrawerOpen = (id: string) => Boolean(drawerDict[id]);

  const openDrawer = (id: string) =>
    setDrawerDict((prev) => ({ ...prev, [id]: true }));

  const closeDrawer = (id: string) => {
    setDrawerDict((prev) => ({ ...prev, [id]: false }));
    if (onCloseCallbacksRef.current[id]) {
      onCloseCallbacksRef.current[id]!();
    }
  };

  return (
    <DrawerContext.Provider
      value={{
        isDrawerOpen,
        drawerDict,
        toggleDrawer,
        openDrawer,
        closeDrawer,
        setOnCloseCallback
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
