import { axiosBaseQuery, createAxiosInstance } from '@client';
import { getEnvironmentVariables } from '@common';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { FormulaResponse, FormulaResult } from '@state';

const { FORMULA_EXECUTOR_API_OVERRIDE, API_URL } = getEnvironmentVariables();

const axiosFormulaExecutionInstance = createAxiosInstance({
  baseURLParam: `${FORMULA_EXECUTOR_API_OVERRIDE ?? API_URL}/port-analysis`
});

export const formulaExecutionApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: '',
    customAxiosInstance: axiosFormulaExecutionInstance
  }),
  reducerPath: 'port-analysis-formula-execution',
  endpoints: (build) => ({
    submitFormulas: build.mutation<FormulaResult[], String>({
      query: (payload) => ({
        url: '/secured/lambda/formula',
        method: 'POST',
        data: payload
      }),
      transformResponse: (response: FormulaResponse) => response[0].results
    })
  })
});

export type StateWithFormulaExecutionApi = {
  [formulaExecutionApi.reducerPath]: ReturnType<
    typeof formulaExecutionApi.reducer
  >;
};

export const { useSubmitFormulasMutation } = formulaExecutionApi;
