import React, { Suspense, lazy, memo } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { getEnvironmentVariables } from '../../common';
import PrivateRoute from './_common/fragment/PrivateRoute';
import { ScrollToTop } from './_common/fragment/ScrollToTop';
import PageLoader from './frontend/fragment/PageLoader';
import MaintenancePage from './frontend/page/maintenance/MaintenancePage';

const FrontEndRouter = lazy(
  () =>
    import(/* webpackChunkName: "FrontEndRouter" */ './frontend/FrontEndRouter')
);

const SecuredRouter = lazy(
  () =>
    import(/* webpackChunkName: "SecuredRouter" */ './secured/SecuredRouter')
);

const { MAINTENANCE_MODE } = getEnvironmentVariables();

export const AppRouter = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      {MAINTENANCE_MODE ? (
        <React.Fragment>
          <Router>
            <Route path="/">
              <MaintenancePage />
            </Route>
          </Router>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Router>
            <ScrollToTop />
            <Switch>
              <PrivateRoute path="/secured">
                <SecuredRouter />
              </PrivateRoute>
              <Route path="/">
                <FrontEndRouter />
              </Route>
            </Switch>
          </Router>
        </React.Fragment>
      )}
    </Suspense>
  );
};

export default memo(AppRouter);
