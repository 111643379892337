import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PortDirectoryHarborComplexFiltersState = {
  vesselType: string | undefined;
  cargo: string | undefined;
  facility: string | undefined;
};

export const initialPortDirectoryHarborComplexFiltersState: PortDirectoryHarborComplexFiltersState =
  {
    vesselType: undefined,
    cargo: undefined,
    facility: undefined
  };

export const portDirectoryHarborComplexFiltersSlice = createSlice({
  name: 'port-directory-harbor-complex-filters',
  initialState: initialPortDirectoryHarborComplexFiltersState,
  reducers: {
    updateFilter: (
      state,
      {
        payload
      }: PayloadAction<Partial<PortDirectoryHarborComplexFiltersState>>
    ) => {
      Object.assign(state, payload);
    },

    resetFilters: (state) => {
      state.vesselType = undefined;
      state.cargo = undefined;
      state.facility = undefined;
    }
  }
});

export const {
  updateFilter: updatePortDirectoryHarborComplexFilter,
  resetFilters: resetPortDirectoryHarborComplexFilters
} = portDirectoryHarborComplexFiltersSlice.actions;

export type StateWithPortDirectoryHarborComplexFilters = {
  [portDirectoryHarborComplexFiltersSlice.name]: PortDirectoryHarborComplexFiltersState;
};
