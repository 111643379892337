import { useFetchFeatureFlagStatusQuery } from '@state/stores/restApi/other/featureFlagApi';
import React, { lazy } from 'react';
import { ToastContainer } from 'react-toastify';
import AppRouter from './AppRouter';
import { withGlobalProviders } from './_common/fragment/GloabalProviders';
import PageLoader from './frontend/fragment/PageLoader';
import { Redirect, Route, BrowserRouter as Router } from 'react-router-dom';
import MaintenancePage from './frontend/page/maintenance/MaintenancePage';

if (import.meta.env.DEV && import.meta.env.VITE_MSW) {
  Promise.all([import('../../../mockServer/msw/handlers'), import('msw')]).then(
    ([{ handlers }, { setupWorker }]) => {
      setupWorker(...handlers).start({ onUnhandledRequest: 'bypass' });
    }
  );
}

const App = (): JSX.Element => {
  const { isLoading, isUninitialized, error } =
    useFetchFeatureFlagStatusQuery();

  if (isUninitialized || isLoading) {
    return <PageLoader />;
  }

  if (error) {
    return (
      <Router>
        <Route path="/">
          <MaintenancePage />
        </Route>
      </Router>
    );
  }

  return (
    <>
      <AppRouter />
      <ToastContainer autoClose={5000} pauseOnFocusLoss={false} icon={false} />
    </>
  );
};

export default withGlobalProviders(App);
