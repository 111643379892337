import { createApi } from '@reduxjs/toolkit/query/react';
import { dynamicBaseGraphqlQuery } from './dynamicBaseGraphqlQuery';

export const sofBaseGraphqlApi = createApi({
  tagTypes: [
    'SOF',
    'SOF_STATUS',
    'SMART_EVENTS',
    'SOF_EVENT_COMMENTS',
    'SOF_COMMENTS',
    'SOF_ACTIVITY_TIMELINE',
    'SOF_SUGGESTED_GENERAL_REMARKS',
    'SOF_SIGNING_STATUS'
  ],
  baseQuery: dynamicBaseGraphqlQuery({
    baseUrl: 'sof/graphql'
  }),
  endpoints: () => ({}),
  reducerPath: 'sof-api'
});
