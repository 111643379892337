import { axiosBaseQuery } from '@client';
import { RequestsAdditionalAgentsForm } from '@module/request/model/RequestsAdditionalAgentsForm';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  QuotationExpenseResponse,
  RequestCaseQuotationsEvaluationResponse
} from '../../types';

const createQuotationExpenseKey = (expense: QuotationExpenseResponse) =>
  [
    expense.name,
    expense.expenseGroupName,
    (expense.parameterOptions ?? [])
      .slice()
      .sort((a, b) => a.parameterId - b.parameterId)
      .map((a) => a.optionId)
      .join('_')
  ]
    .filter(Boolean)
    .join('_');

export const requestCaseApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: 'secured/request-case' }),
  reducerPath: 'request-case-api',
  endpoints: (build) => ({
    evaluateRequestCase: build.query<
      RequestCaseQuotationsEvaluationResponse,
      { requestCaseId: string }
    >({
      query: ({ requestCaseId }) => ({
        method: 'GET',
        url: `/${requestCaseId}/evaluate`
      }),
      transformResponse(res: RequestCaseQuotationsEvaluationResponse) {
        const quotations = res.quotations.map((q) => ({
          ...q,
          husbandryExpensesSection: {
            ...q.husbandryExpensesSection,
            expenses: (q.husbandryExpensesSection.expenses ?? []).map((e) => ({
              ...e,
              key: createQuotationExpenseKey(e)
            }))
          },
          portExpensesSection: {
            ...q.portExpensesSection,
            expenses: (q.portExpensesSection.expenses ?? []).map((e) => ({
              ...e,
              key: createQuotationExpenseKey(e)
            }))
          },
          agencyExpensesSection: {
            ...q.agencyExpensesSection,
            expenses: (q.agencyExpensesSection.expenses ?? []).map((e) => ({
              ...e,
              key: createQuotationExpenseKey(e)
            }))
          }
        }));
        return { ...res, quotations };
      }
    }),
    shareRequestCase: build.mutation<
      void,
      { requestCaseId: number; emails: string[] }
    >({
      query: ({ requestCaseId, emails }) => ({
        method: 'POST',
        url: `/${requestCaseId}/share`,
        data: { emails }
      })
    }),
    addMoreAgents: build.mutation<void, RequestsAdditionalAgentsForm>({
      query: (data: RequestsAdditionalAgentsForm) => ({
        method: 'POST',
        url: '/submit-additional-request-for-quotation',
        data
      })
    })
  })
});

export const {
  useEvaluateRequestCaseQuery,
  useShareRequestCaseMutation,
  useAddMoreAgentsMutation
} = requestCaseApi;
