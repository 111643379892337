import { portAnalysisApi } from './portAnalysisServiceApi';
import { LocationSearchResponse } from '@state/types/LocationSearchResponse';
import { LocationData } from '@state/types/LocationData';
import { RefData } from 'components';

const BASE_URL = '/secured';

const locationApi = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    searchLocation: build.query<LocationSearchResponse[], string>({
      query: (location) => ({
        method: 'GET',
        url: `${BASE_URL}/location/search`,
        params: { location: location }
      })
    }),
    fetchLocation: build.query<
      LocationData,
      { locationId: number; type: string }
    >({
      query: ({ locationId, type }) => ({
        method: 'GET',
        url: `${BASE_URL}/location/${locationId}`,
        params: { type: type }
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_ANALYSIS_LOCATION' as const, id: arg.locationId }]
          : ['PORT_ANALYSIS_LOCATION']
    }),
    fetchLocationRelatedData: build.query<RefData[], number>({
      query: (locationId) => ({
        method: 'GET',
        url: `${BASE_URL}/location/${locationId}/related-data`
      })
    }),
    fetchLocationData: build.query<
      LocationData[],
      { locationId: number; type: string }
    >({
      query: ({ locationId, type }) => ({
        method: 'GET',
        url: `${BASE_URL}/${type}/${locationId}/location_data`
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_ANALYSIS_LOCATION' as const, id: arg.locationId }]
          : ['PORT_ANALYSIS_LOCATION']
    })
  }),
  overrideExisting: false
});

export const {
  useSearchLocationQuery,
  useLazySearchLocationQuery,
  useFetchLocationQuery,
  useFetchLocationRelatedDataQuery,
  useLazyFetchLocationQuery,
  useFetchLocationDataQuery,
  useLazyFetchLocationDataQuery
} = locationApi;
